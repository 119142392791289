@import 'variables';

$pill-error-color: $chr-action-needed-red;
$pill-warning-color: $chr-action-needed-orange;

.pill {
  display: inline-flex;
  flex-grow: 0;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #36383A;
  border-radius: 4px;
  text-align: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 4px 8px;
  height: 24px;
  font-weight: 600;
  gap: 4px;

  &.default {
    background-color: #E5E6E7;
  }

  &.error {
    background-color: #FFEFEF;
    border-color: $pill-error-color;
    color: $pill-error-color;
  }

  &.warning {
    background-color: #FBF7E6;
    border-color: #FDC20C;
    color: $pill-warning-color;
  }
}
